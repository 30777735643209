<template>

  <div>
    <b-overlay
      :show="showCompaniesLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5>
                Companies
              </h5>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          class="position-relative"
          :items="companies"
          responsive
          :fields="tableColumns"
          primary-key="companyName"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Company -->
          <template #cell(company)="data">
            <b-media vertical-align="center">
              {{ data.item.companyName }}
            </b-media>
          </template>

          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-button
              v-if="data.item.id != userData['custom:override_tenant_id']"
              variant="primary"
              @click="swapCompany(data.item.companyName, data.item.id)"
            >
              <span class="text-nowrap">Swap to Company</span>
            </b-button>
            <b-button
              v-else
              disabled
            >
              <span class="text-nowrap">Current Company</span>
            </b-button>
          </template>

        </b-table>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BOverlay,
  // BPagination,
} from 'bootstrap-vue'
import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BOverlay,
  },
  data() {
    return {
      companies: null,
      showCompaniesLoader: true,
      tableColumns: [
        { key: 'companyName', sortable: true },
        { key: 'action' },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.fetchCompanies()
  },
  methods: {
    fetchCompanies() {
      axios
        .get('/admin/tenants')
        .then(response => {
          this.companies = response.data
          console.log(response)
          this.showCompaniesLoader = false
        })
        .catch(error => console.log(error))
    },
    swapCompany(companyName, tenantId) {
      this.showCompaniesLoader = true
      axios
        .put('/admin/swap-to', { tenantId })
        .then(() => {
          useJwt.refreshToken().then(r => {
            // update tokens
            useJwt.setToken(r.data.idToken)
            useJwt.setRefreshToken(r.data.refreshToken)
            // set company data
            this.userData.companyName = companyName
            this.userData['custom:override_tenant_id'] = tenantId
            localStorage.setItem('userData', JSON.stringify(this.userData))
            // goto home page
            this.$router.replace(getHomeRouteForLoggedInUser(this.userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${this.userData.given_name}`,
                    timeOut: 2500,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${this.userData.displayRole}`,
                  },
                })
              })
          })
        })
        .catch(error => console.log(error))
    },
  },
}
</script>
